import {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { get, flatten, uniq } from 'lodash';
import axios from 'axios';

import {
  isOffline, JwtTokenInterceptor, logger, parseApiMessages,
} from '../../api';
import { translateErrorMessage } from '../../utils';
import { useDialog } from '../Dialog';
import ApiStatusContext, { ClearAllProgress } from './ApiStatusContext';
import setupCordovaHttpPlugin from './setupCordovaHttpPlugin';

type HideProgress = (urlPattern: string) => void;
type ShowProgress = (urlPattern: string, showProgressOnUrls: true | string[]) => void;

const useApiProgress = (showProgress: ShowProgress, hideProgress: HideProgress) => {
  useEffect(() => {
    const onRequest = (config: any) => {
      if (config?.showProgress) {
        showProgress(config.urlPattern, config.showProgress);
      }
      return config;
    };

    const onResponse = (response: any, error?: any) => {
      const config = response?.config || error?.config;
      if (config?.showProgress) {
        hideProgress(config.urlPattern);
      }
      return error ? Promise.reject(error) : response;
    };

    const requestInterceptor = axios.interceptors.request.use(
      (config) => onRequest(config),
      (error) => Promise.reject(error),
    );

    const responseInterceptor = axios.interceptors.response.use(
      (response) => onResponse(response),
      (error) => onResponse(error.response, error),
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [showProgress, hideProgress]);
};

const getDialogMessage = (response: any, t: T) => {
  if (get(response, 'headers["content-type"]', '').includes('application/json') ||
    get(response, 'headers["Content-Type"]', '').includes('application/json')) {
    const messages = parseApiMessages(response?.data, null);
    return translateErrorMessage(t, messages[0]) || messages[0] || translateErrorMessage(t, 'network.error');
  }
  if (response?.status === 503) {
    const method = get(response, 'config.method', '').toUpperCase();
    const url = response?.config?.url;

    return (
      <>
        {translateErrorMessage(t, 'service.unavailable')}
        <br />
        {url && <p style={{ wordBreak: 'break-all' }}>({`${method} ${url}`})</p>}
      </>
    );
  }
  return translateErrorMessage(t, 'network.error');
};

const transformToLogResponse = (response: any) => (response?.config?.headers ? ({
  config: {
    ...response.config,
    headers: {
      ...response.config.headers,
      Authorization: 'Bearer *****',
    },
  },
}) : response);

const useApiMessageHandler = (t: T, setMessageHandlerInitialized: Function) => {
  const { showDialog, showServiceErrorMessage } = useDialog();

  useEffect(() => {
    const onAxiosSuccessResponse = (response: any) => {
      const { handleErrorFieldLocally, ignoreErrors } = response?.config || {};
      const error = response?.data?.error;

      if (error) {
        const logResponse = transformToLogResponse(response);
        logger.error({ logResponse });

        if (!handleErrorFieldLocally && !ignoreErrors) {
          showServiceErrorMessage(error, true);
        }
      }
      return response;
    };

    const onAxiosErrorResponse = (response: any, error?: any) => {
      const config = response?.config || error?.config || {};
      const { ignoreErrors, ignoreSessionErrors, persistType } = config;
      const { status } = response || {};
      if (!ignoreErrors && !axios.isCancel(error) && (ignoreSessionErrors || (status !== 401 && status !== 403)) && (!persistType || !isOffline(status))) {
        const logError = {
          ...error,
          response: transformToLogResponse(response),
        };
        logger.error(logError);

        showDialog({
          title: t('error.serviceError'),
          content: getDialogMessage(response, t),
          buttons: t('button.ok'),
        });
      }

      return error ? Promise.reject(error) : response;
    };

    // subscribe to all response and error and display messages
    const interceptor = axios.interceptors.response.use(
      onAxiosSuccessResponse,
      (error) => onAxiosErrorResponse(error.response, error),
    );

    setMessageHandlerInitialized(true);

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [setMessageHandlerInitialized, showDialog, showServiceErrorMessage, t]);
};

type ProgressObject = {
  urlPattern: string;
  showProgressOnUrls: true | string[];
};

const getShowProgressOnUrls = (progress: ProgressObject[]): string[] => {
  const urls = progress.map(({ showProgressOnUrls }) => showProgressOnUrls);

  if (urls.length === 0) {
    return [];
  }
  if (urls.some((item) => item === true)) {
    return ['/'];
  }
  return uniq(flatten(urls as any));
};

const ApiStatusProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();

  const [messageHandlerInitialized, setMessageHandlerInitialized] = useState(false);
  const [progress, setProgress] = useState<ProgressObject[]>([]);

  const showProgress: ShowProgress = useCallback((urlPattern, showProgressOnUrls) => {
    setProgress((prevState) => [...prevState, { urlPattern, showProgressOnUrls }]);
  }, []);
  const hideProgress: HideProgress = useCallback((urlPattern) => {
    setProgress((prevState) => {
      const index = prevState.findIndex((item) => item.urlPattern === urlPattern);
      if (index === -1) {
        return prevState;
      }
      const newProgress = [...prevState];
      newProgress.splice(index, 1);
      return newProgress;
    });
  }, []);
  const clearAllProgress: ClearAllProgress = useCallback(() => {
    setProgress([]);
  }, []);
  useApiProgress(showProgress, hideProgress);
  useApiMessageHandler(t, setMessageHandlerInitialized);

  const ctx = useMemo(() => ({
    showProgressOnUrls: getShowProgressOnUrls(progress),
    clearAllProgress,
  }), [clearAllProgress, progress]);

  useEffect(() => {
    setupCordovaHttpPlugin();
    // ADD token interceptor
    logger.info('Setup token interceptor by server config', window.IADP_SERVERS);
    JwtTokenInterceptor.initServerConfig();
    const interceptor = axios.interceptors.request.use(JwtTokenInterceptor.interceptor as any);
    return () => axios.interceptors.request.eject(interceptor);
  }, []);

  if (!messageHandlerInitialized) {
    return <div className="waiting">Waiting for api status loaded</div>;
  }

  return <ApiStatusContext.Provider value={ctx}>{children}</ApiStatusContext.Provider>;
};

export const useApiStatus = () => useContext(ApiStatusContext);

export default ApiStatusProvider;
