import common from '@material-ui/core/colors/common';
import { alpha } from '@lsy-netline-ui/netline-ui/styles';

import { THEME_BREAKPOINTS } from '@crew-webui/common/consts';

const text = {
  primary: '#111',
  secondary: '#757575',
  label: '#212121',
  secondaryLabel: '#616161',
};

const button = {
  backgroundColor: '#177FD2',
  textColor: common.white,
  disabledColor: alpha(text.secondary, 0.2),
  hover: '#177FD2',
};

const defaultTheme = {
  breakpoints: THEME_BREAKPOINTS,
  palette: {
    background: {
      default: '#ffffff',
    },
    text,
    primary: {
      main: '#177FD2',
      contrastText: common.white,
    },
    error: {
      main: '#CF3B3B',
    },
    unreadCardBackgroundColor: '#E2F3FF',
    messageStatusIconColor: '#177FD2',
  },
  typography: {
    h4: {
      color: text.primary,
      fontSize: 16,
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: '20px',
    },
    h5: {
      color: text.primary,
      fontSize: 16,
      fontWeight: 400,
      letterSpacing: 0.15,
      lineHeight: '24px',
    },
    h6: {
      color: text.secondaryLabel,
      fontSize: 12,
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: '20px',
      textTransform: 'uppercase',
    },
    body1: {
      color: text.primary,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: '18px',
    },
    body2: {
      color: text.secondary,
      fontSize: 14,
      letterSpacing: 0.25,
      lineHeight: '20px',
    },
    caption: {
      color: text.secondaryLabel,
      fontSize: 12,
      letterSpacing: 0,
      lineHeight: '16px',
    },
    overline: {
      color: text.secondary,
      fontSize: 10,
      fontWeight: 500,
      letterSpacing: 1.5,
      lineHeight: '16px',
    },
    shortcutText: {
      fontSize: '15px',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        'borderRadius': 3,
        'minHeight': 32,
        'lineHeight': '14px',

        '&$disabled': {
          color: button.disabledColor,
        },
      },
      contained: {
        '&, &&:hover': {
          '&&$disabled': {
            backgroundColor: '#ccc',
            opacity: 1,
            color: 'rgba(0, 0, 0, 0.26)',
          },
        },
      },
      sizeLarge: {
        paddingTop: 10,
        paddingBottom: 10,
        fontSize: 14,
        lineHeight: '16px',
      },
    },
    MuiBottomNavigationAction: {
      root: {
        '&$iconOnly': {
          paddingTop: 8,
        },
      },
    },
    MuiIconButton: {
      root: {
        'color': text.secondary,

        '&$disabled': {
          color: button.disabledColor,
        },
      },
    },
    MuiSvgIcon: {
      fontSizeSmall: {
        fontSize: 16,
      },
    },
    MuiFormHelperText: {
      root: {
        '&$disabled': {
          color: '#BDBDBD',
        },
      },
    },
  },
};

export default defaultTheme;
