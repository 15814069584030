import { reduce, set } from 'lodash';

import { logger } from '@crew-webui/common/api';

import createTheme from 'theme/createTheme';
import * as themes from 'theme';
import * as profiles from 'profile';

const ProfileMap = reduce(profiles, (result, profile, name: string) => {
  if (!(themes as any)[name]) {
    logger.warn(`No theme found for profile '${name}', so 'default' theme is used! Please check!`);
  }

  return set(result, name, {
    theme: createTheme((themes as any)[name] || themes.default),
    profile,
  });
}, {});

export default ProfileMap;
