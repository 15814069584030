import { makeStyles } from '@lsy-netline-ui/netline-ui';

import { useCrewAuth } from '@crew-webui/common/hooks';
import { CookieBanner, NoConnection } from '@crew-webui/common/view/components';

import ApplicationsPage from 'view/ApplicationsPage/ApplicationsPage';
import AppHeader from 'view/AppHeader/AppHeader';
import AppFooter from 'view/AppFooter/AppFooter';

import styles from './StartPage.styles';

const useStyles = makeStyles(styles, { name: 'StartPage' });

const StartPage = () => {
  const classes = useStyles();
  const {
    ready, authenticated,
  } = useCrewAuth();

  if (!ready) {
    return null;
  }

  return (
    <>
      <AppHeader />
      {authenticated && (
        <>
          <div className={classes.columnContainer}>
            <div className={classes.mainContainer}>
              <ApplicationsPage />
            </div>
          </div>
          {!window.cordova && <CookieBanner />}
        </>
      )}
      <NoConnection />
      <AppFooter />
    </>
  );
};

export default StartPage;
