import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, makeStyles } from '@lsy-netline-ui/netline-ui';
import { FlightTakeoff as FlightTakeoffIcon } from '@material-ui/icons';

import { useAuth, usePublicConfig } from '@crew-webui/common/hooks';
import { getImageServerUrl, logger } from '@crew-webui/common/api';

import { Application, RightValues } from 'consts';
import { useConfig } from 'hooks';
import styles from './ApplicationsPage.styles';

type ApplicationShortcut = {
  label: string;
  icon: string;
  href: string;
  enabled: boolean;
};

const useStyles = makeStyles(styles, { name: 'ApplicationsPage' });

const calculateRight = (map: Record<string, string>, values: RightValues, rights: string[]) => {
  const keysByRights = Object.keys(map).reduce((acc: string[], right: string) => {
    if (!rights || rights.indexOf(right) < 0) {
      return acc;
    }
    const val: string = map[right];
    acc.push(val);
    return acc;
  }, []).sort();
  const match = keysByRights.length > 0 && Object.keys(values).find((keyOfValue) => {
    const keysByValue = keyOfValue.split(',').map((key) => key.trim()).sort();
    return !keysByRights.some((keyByRight, idx) => {
      const keyByValue = keysByValue[idx];
      return keyByValue !== '~' && keyByValue !== keyByRight;
    });
  });
  return match && values[match];
};

const pushToApplications = (
  result: ApplicationShortcut[],
  operatingAirlines: string[] | undefined,
  label: string,
  icon: string,
  href: string,
  enabled: boolean,
) => {
  if (operatingAirlines) {
    operatingAirlines.forEach((airline) => {
      result.push({
        label: label.replace(/\{airline\}/g, airline),
        icon: icon.replace(/\{airline\}/g, airline),
        href: href.replace(/\{airline\}/g, airline),
        enabled,
      });
    });
  } else {
    result.push({
      label, icon, href, enabled,
    });
  }
};

/**
 * Return an array with the available and visible applications
 *
 * @return {Object[]}
 */
const getAvailableApplications = (applications: Application[], rights: string[], operatingAirlines: string[]) => {
  const result: ApplicationShortcut[] = [];
  applications.forEach(({
    right, label, icon, href, airlines, visible, enabled,
  }) => {
    if (typeof right !== 'string' && right !== undefined) {
      const { values, map } = right;
      const labelIcon = calculateRight(map, values, rights);
      if (labelIcon && visible) {
        pushToApplications(result, airlines ? operatingAirlines : undefined, labelIcon.label, labelIcon.icon, href, enabled);
      }
    } else if (right !== undefined && visible && rights && rights.indexOf(right) >= 0) {
      pushToApplications(result, airlines ? operatingAirlines : undefined, label || '', icon || '', href, enabled);
    } else if (right === undefined && label && visible && enabled) {
      pushToApplications(result, airlines ? operatingAirlines : undefined, label || '', icon || '', href, enabled);
    }
  });
  logger.info('Applications loaded', result);
  return result;
};

const transformPemsApplications = (pemsApplications: any) => (
  pemsApplications.map((app: any) => (
    {
      visible: true,
      enabled: app.enabled,
      label: app.label,
      icon: app.icon,
      href: app.uri,
    }

  ))
);

const ApplicationsPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { user } = useAuth();
  const { applications, logoUrl: clwsLogoUrl } = useConfig();
  const { configuration: { applicationWebLinks } = { configuration: { applicationWebLinks: [] } }, rights, operatingAirline } = user;
  const { logoUrl } = usePublicConfig();

  const visibleApps = useMemo(() => {
    const apps = !applications?.length ? transformPemsApplications(applicationWebLinks) : applications;
    return getAvailableApplications(
      apps,
      rights,
      operatingAirline?.length > 0 ? operatingAirline.split(',') : [],
    );
  }, [applications, applicationWebLinks, rights, operatingAirline]);

  // Evaluates whether the logo is coming from the clws-config.json or the login file
  const visibleLogo = useMemo(
    () => (
      clwsLogoUrl || logoUrl ? (
        <img className={classes.logo} src={getImageServerUrl(clwsLogoUrl || logoUrl)} alt="logo" />
      ) : (
        <FlightTakeoffIcon className={classes.defaultLogo} />
      )
    ),
    [classes.defaultLogo, classes.logo, clwsLogoUrl, logoUrl],
  );

  return (
    <div className={classes.root}>
      <div className={classes.header} data-test-id="header">
        <div className={classes.leftHeader} data-test-id="left-header">{t('components.myApplications')}</div>
        {visibleLogo}
      </div>
      <div className={classes.shortcutContainer}>
        {visibleApps?.map((appLink: any, i: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <Link className={`${classes.shortcut} ${appLink.enabled ? '' : classes.disabled}`} key={i} href={appLink.href} target="_blank">
            <div
              className={classes.shortcutIcon}
              style={{
                backgroundImage: `url("./images/icons/${appLink.icon}")`,
              }}
            />
            <div className={classes.shortcutLabel}>{appLink.label}</div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ApplicationsPage;
