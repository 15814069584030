import { createRoot } from 'react-dom/client';
import { HashRouter } from 'react-router-dom';

import '@crew-webui/common/commonSideEffects';
import { initI18next, syncStorage, ServerConfigHandler } from '@crew-webui/common/api';
import { CrewComDomain, PemsDomain } from '@crew-webui/common/consts';
import {
  ApiStatusProvider,
  AppCommonProvider,
  ConnectionProvider,
  DialogProvider,
  DomainContext,
  MultiColumnProvider,
  UserPreferencesProvider,
  ProfileAndThemeProvider,
} from '@crew-webui/common/hooks';
import { ErrorBoundary, MessageDialog } from '@crew-webui/common/view/components';

import { languageFiles } from 'consts';
import { ConfigProvider } from 'hooks';
import { ProfileMap } from 'utils';
import App from 'view/App';

import './index.css';

const domainContext = { pemsDomain: PemsDomain.NLWS, crewComDomain: CrewComDomain.IADP, routePrefix: '' };

const renderApp = () => {
  const container = document.getElementById('root');
  const root = createRoot(container!);
  root.render(
    <DomainContext.Provider value={domainContext}>
      <HashRouter>
        <ProfileAndThemeProvider ProfileMap={ProfileMap}>
          <ErrorBoundary>
            <MultiColumnProvider>
              <DialogProvider>
                <ConnectionProvider>
                  <ApiStatusProvider>
                    <AppCommonProvider>
                      <MessageDialog />
                      <ConfigProvider>
                        <UserPreferencesProvider>
                          <App />
                        </UserPreferencesProvider>
                      </ConfigProvider>
                    </AppCommonProvider>
                  </ApiStatusProvider>
                </ConnectionProvider>
              </DialogProvider>
            </MultiColumnProvider>
          </ErrorBoundary>
        </ProfileAndThemeProvider>
      </HashRouter>
    </DomainContext.Provider>,
  );
};

const startApp = async () => {
  // To disable the font size setting from operation system in native application
  // https://github.com/phonegap/phonegap-mobile-accessibility#mobileaccessibilityusepreferredtextzoomboolean
  if (window.MobileAccessibility) {
    window.MobileAccessibility.usePreferredTextZoom(false);
  }

  await ServerConfigHandler.initServerConfig();

  renderApp();

  if (module.hot && process.env.NODE_ENV !== 'production') {
    module.hot.accept('view/App', () => renderApp());
  }
};

initI18next(languageFiles);
syncStorage.setKeyPrefix('netlineWorkspace');

if (window.cordova) {
  document.addEventListener('deviceready', startApp);
} else {
  startApp();
}
