import { createStyles } from '@lsy-netline-ui/netline-ui';

import dotYellow from 'resources/images/dot-yellow-3x3.png';
import impulseLines from 'resources/images/impulse-lines-yellow.png';
import headerBackground from 'resources/images/header-background-sky-big.jpg';

export default ({ breakpoints, palette, spacing }: Theme) => createStyles({
  root: {
    flexGrow: 0,
    flexShrink: 0,
  },
  titleold: {
    color: 'inherit',
    textTransform: 'none',
    [breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  profileLink: {
    textDecoration: 'none',
    color: palette.grey[500],
  },
  menuButton: {
    marginRight: spacing(2),
  },
  appBar: {
    backgroundImage: `url(${dotYellow}), url(${impulseLines}), url(${headerBackground})`,
    backgroundRepeat: 'repeat-x, no-repeat, no-repeat',
    backgroundPosition: 'bottom, bottom right 30px, center left',
    [breakpoints.down('sm')]: {
      height: 50,
      backgroundPosition: 'bottom, bottom right -200px, center left',
    },
    height: 160,
  },
  toolbar: {
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  logoContainer: {
    padding: spacing(2),
    overflow: 'hidden',
    backgroundColor: 'rgba(255,255,255, 0.6)',
    [breakpoints.down('sm')]: {
      padding: spacing(0.5),
    },
  },
  logo: {
    maxHeight: 110,
    maxWidth: 350,
    [breakpoints.down('sm')]: {
      maxHeight: 25,
      maxWidth: 100,
    },
  },
  defaultLogo: {
    maxHeight: 110,
    maxWidth: 350,
    color: palette.grey[600],
    [breakpoints.down('sm')]: {
      maxHeight: 25,
      maxWidth: 100,
    },
  },
  rightContainer: {
    height: '100%',
  },
  rightTop: {
    textAlign: 'right',
    fontSize: 11,
    height: 32,
    color: palette.blue[1200],
    [breakpoints.down('sm')]: {
      height: 16,
    },
  },
  rightBottom: {
    position: 'relative',
    color: 'white',
    fontSize: 31,
    fontFamily: '"Lufthansa Medium", Arial, sans-serif',
    textTransform: 'none',
    left: -40,
    marginTop: 30,
    [breakpoints.down('sm')]: {
      fontSize: 14,
      marginTop: 10,
    },
  },
  logoutButton: {
    color: palette.text.primary,
    textTransform: 'none',
  },
  appName: {
    fontWeight: 'normal',
    fontFamily: '"Lufthansa Light", Arial, sans-serif',
  },
});
