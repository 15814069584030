import { useContext, useEffect, useState } from 'react';

import { logger, loadConfigByPath } from '@crew-webui/common/api';
import { PublicConfigProvider, useDialog } from '@crew-webui/common/hooks';

import ConfigContext, { DefaultConfig, EMPTY_CONTEXT } from './ConfigContext';

const ConfigProvider = ({ children }: { children: React.ReactNode }) => {
  const { showServiceErrorMessage } = useDialog();

  const [configs, setConfigs] = useState<DefaultConfig | undefined>();

  // Load config json
  useEffect(() => {
    if (configs) {
      return;
    }
    loadConfigByPath('./clws-config.json').then((response: DefaultConfig) => {
      if (typeof response !== 'object') {
        throw new Error();
      }
      setConfigs(response);
    }).catch(() => {
      logger.error('Failed to load configuration.');
      setConfigs(EMPTY_CONTEXT);
    });
  }, [configs, showServiceErrorMessage]);

  if (!configs) {
    return null;
  }

  return (
    <ConfigContext.Provider value={configs}>
      <PublicConfigProvider useWithPems={!configs.applications?.length}>
        {children}
      </PublicConfigProvider>
    </ConfigContext.Provider>
  );
};

export const useConfig = () => useContext(ConfigContext);

export default ConfigProvider;
