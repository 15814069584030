import en from 'resources/lang/en.json';

export const languages = [
  {
    key: 'en',
    label: 'English',
    file: en,
  },
];

export const languageFiles = languages.reduce((acc, curr) => ({ ...acc, [curr.key]: curr.file }), {});
