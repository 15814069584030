import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette }: Theme) => createStyles({
  root: {
    flexGrow: 0,
    flexShrink: 0,
    backgroundColor: palette.grey[300],
    fontSize: 12,
    height: 40,
    lineHeight: '40px',
    paddingLeft: 20,
    color: '#5a5a5a',
  },
  versionButton: {
    color: palette.text.primary,
    textTransform: 'none',
  },
});
