import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, makeStyles } from '@lsy-netline-ui/netline-ui';

import { useDialog, useProfile } from '@crew-webui/common/hooks';

import styles from './AppFooter.styles';

const useStyles = makeStyles(styles, { name: 'AppFooter' });

const appVersion = process.env.REACT_APP_VERSION;
const buildTime = process.env.REACT_APP_BUILD_DATE;

const AppFooter = () => {
  const classes = useStyles();
  const { profile: { applicationPrefix, applicationName } } = useProfile();
  const { t } = useTranslation();
  const { showDialog } = useDialog();

  const year = useMemo(() => new Date().getFullYear(), []);
  const versionTitle = useMemo(() => `${applicationPrefix} ${applicationName} ${t('version.title')}`, [applicationPrefix, applicationName, t]);

  const showVersion = useCallback(() => {
    showDialog({
      title: versionTitle,
      content: (
        <span>
          {`${t('version.version')}: ${appVersion}`}
          <br />
          {`${t('version.buildTime')}: ${buildTime}`}
        </span>
      ),
      buttons: t('button.ok'),
    });
  }, [showDialog, t, versionTitle]);

  return (
    <div className={classes.root}>
      <b>&copy;&nbsp;{ year }&nbsp;</b>
      Lufthansa Systems &nbsp;&nbsp;&nbsp;&nbsp;
      <Button onClick={showVersion} className={classes.versionButton}>{versionTitle}</Button>
    </div>
  );
};

export default AppFooter;
