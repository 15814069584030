import { uniq } from 'lodash';
import { DateTime, Duration, DurationObject } from 'luxon';

import { SmartPunctationSymbols } from '../consts';

export const translateErrorMessage = (t: T, key: string | undefined) => {
  if (key === undefined) {
    return undefined;
  }

  const translatedErrorMessage = t(`errorMap#${key}`, { keySeparator: '#' });

  if (translatedErrorMessage === `errorMap#${key}`) {
    return key;
  }

  return translatedErrorMessage;
};

export const getErrorMessageFromResponse = (t: T, error?: { items?: any }) => {
  const errObj = error?.items?.[0];
  const { errMessage, errId } = errObj || {};

  const translatedKey = translateErrorMessage(t, errId);
  if (translatedKey !== errId) {
    return translatedKey;
  }
  return errMessage || translateErrorMessage(t, 'service.unavailable');
};

export const replaceVars = (str: string | undefined, vars: Record<string, string> = {}): string => (
  !str ? '' : Object.keys(vars).reduce((acc, name) => acc.replace(`{${name}}`, vars[name]), str)
);

// To only allow printable characters
const printableChars = '\x08-\x7F';
// Including special characters from French and German languages
const printableCharsWithAccents = '\x08-\x7F-\u00C0-\u00D6-\u00D8-\u00F6-\u00F8-\u00FF\u0153';

const allowedChars = `${printableChars}${Object.keys(SmartPunctationSymbols).join('')}`;
const allowedCharsWithAccents = `${printableCharsWithAccents}${Object.keys(SmartPunctationSymbols).join('')}`;

export const printableRule = {
  regex: new RegExp(`^[${allowedChars}]*$`),
  errorMessageCreator: (t: T) => ({ originalValue }: { originalValue: string }) => {
    const stringWithOnlyWrongChars = originalValue.replace(new RegExp(`[${allowedChars}]`, 'gi'), '');
    const wrongChars = uniq(stringWithOnlyWrongChars.split('')).join('');
    const specChars = '.?!,-:&@+#';
    return t('myData.validation.notSupportedChars', { specChars, wrongChars });
  },
};

export const printableRuleWithAccents = {
  regex: new RegExp(`^[${allowedCharsWithAccents}]*$`),
  errorMessageCreator: (t: T) => ({ originalValue }: { originalValue: string }) => {
    const stringWithOnlyWrongChars = originalValue.replace(new RegExp(`[${allowedCharsWithAccents}]`, 'gi'), '');
    const wrongChars = uniq(stringWithOnlyWrongChars.split('')).join('');
    const specChars = '.?!,-:&@+#';
    return t('myData.validation.notSupportedChars', { specChars, wrongChars });
  },
};

/**
 * Creates a debounced function that delays invoking func until after wait milliseconds have elapsed
 * since the last time the debounced function was invoked.
 */
export const debounce = <F extends (...args: any[]) => any>(f: F, wait = 100) => {
  let timer: number | undefined;

  return (...args: Parameters<F>): Promise<ReturnType<F>> => {
    clearTimeout(timer);

    return new Promise((resolve) => {
      timer = window.setTimeout(
        () => resolve(f(...args)),
        wait,
      );
    });
  };
};

export const getDateFormatted = (profile: Record<string, any>, date: string) => (
  date && DateTime.fromISO(date, { zone: 'utc' }).toUTC().toFormat(profile.dateFormats.SHORT_DATE_SEPARATED)
);

export const convertDate = (dateStr: string) => {
  const date = DateTime.fromISO(dateStr);
  return DateTime.utc(date.year, date.month, date.day);
};

export const formatTime = (time: string, format: string) => (
  time ? DateTime.fromISO(time).toUTC().toFormat(format) : undefined
);

export const displayTime = (time: string, format: string, postfix: string) => (
  postfix ? `${formatTime(time, format)} ${postfix}` : formatTime(time, format)
);

export const formatDuration = (duration: Duration, format: string) => {
  if (!duration.isValid) {
    throw new Error(duration.invalidReason!);
  }
  // The format param must be converted, because the Duration's toFormat method uses different time tokens
  const outFormat = format.toLocaleLowerCase();
  // Negative sign correction
  if (duration.valueOf() < 0) {
    return `-${duration.negate().toFormat(outFormat)}`;
  }
  return duration.toFormat(outFormat);
};

export const formatDurationObject = (time: DurationObject, format: string) => formatDuration(Duration.fromObject(time), format);

export const rotateArrayRight = ([...arr]) => {
  const last = arr.pop();
  return [last, ...arr];
};

export const allowedCharacters = (e: React.KeyboardEvent) => !['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ',', 'Backspace', 'Delete'].includes(e.key) && e.preventDefault();

// SHA-256 hash function
export const simpleHash = (str: string): string => {
  let hash = 0;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < str.length; i++) {
    const charCode = str.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + charCode; // XOR operation
  }

  return hash.toString(16);
};
