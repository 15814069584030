import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppBar, Button, Toolbar, Typography,
  makeStyles,
} from '@lsy-netline-ui/netline-ui';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { FlightTakeoff as FlightTakeoffIcon } from '@material-ui/icons';

import {
  useCrewAuth, useProfile, usePublicConfig,
} from '@crew-webui/common/hooks';
import { getImageServerUrl } from '@crew-webui/common/api';

import { useConfig } from 'hooks';
import styles from './AppHeader.styles';

const useStyles = makeStyles(styles, { name: 'AppHeader' });

const AppHeader = () => {
  const classes = useStyles();
  const {
    profile: {
      applicationPrefix,
      applicationName,
    },
  } = useProfile();
  const { t } = useTranslation();
  const { authenticated, logout, user: { userName } } = useCrewAuth();
  const { logoUrl: clwsLogoUrl } = useConfig();
  const { logoUrl } = usePublicConfig();

  // Evaluates whether the logo is coming from the clws-config.json or the login file
  const visibleLogo = useMemo(
    () => (
      clwsLogoUrl || logoUrl ? (
        <img className={classes.logo} src={getImageServerUrl(clwsLogoUrl || logoUrl)} alt="logo" />
      ) : (
        <FlightTakeoffIcon className={classes.defaultLogo} />
      )
    ),
    [classes.defaultLogo, classes.logo, clwsLogoUrl, logoUrl],
  );

  return (
    <div>
      <AppBar
        position="static"
        className={classes.appBar}
      >
        <Toolbar className={classes.toolbar}>
          <div className={classes.logoContainer}>
            {visibleLogo}
          </div>
          <div className={classes.rightContainer}>
            <div className={classes.rightTop}>
              <span>&nbsp;{authenticated && t('login.loggedIn', { userName })}</span>
              {authenticated && (
                <Button
                  onClick={() => logout()}
                  className={classes.logoutButton}
                  startIcon={<ArrowRightAltIcon />}
                >
                  { t('button.logout') }
                </Button>
              )}
            </div>
            <Typography variant="h6" className={classes.rightBottom}>
              { applicationPrefix }
              <span className={classes.appName}>{ applicationName }</span>
            </Typography>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default AppHeader;
