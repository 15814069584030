import { createContext } from 'react';

import { Application } from 'consts';

export interface DefaultConfig {
  applications: Application[];
  logoUrl: string;
  passwordChangeAllowed?: boolean;
}

type ConfigContextType = DefaultConfig;

export const EMPTY_CONTEXT: DefaultConfig = {
  applications: [],
  logoUrl: '',
};

const ConfigContext = createContext<ConfigContextType>(EMPTY_CONTEXT);

export default ConfigContext;
