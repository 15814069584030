import { createStyles } from '@lsy-netline-ui/netline-ui';

export default () => createStyles({
  mainContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1',
    overflow: 'hidden',
  },
  columnContainer: {
    'position': 'relative',
    'flex': '1 1',
    'display': 'flex',
    'maxHeight': '100%',
    'minHeight': 0,

    '& > *': {
      flex: '1 1 0',
      width: 0,
      height: '100%',
      boxSizing: 'border-box',
      overflow: 'auto',
    },
  },
});
