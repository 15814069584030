import { createStyles } from '@lsy-netline-ui/netline-ui';

import defaultTheme from 'theme/defaultTheme';

export default ({ breakpoints, palette }: Theme) => createStyles({
  root: {
    flexGrow: 0,
    flexShrink: 0,
    padding: '5px 10px',
  },
  header: {
    display: 'flex',
    height: 100,
    justifyContent: 'space-between',
    flexGrow: 0,
  },
  leftHeader: {
    padding: '10px 0px 0px 20px',
    flexGrow: 1,
    fontWeight: 'bold',
    fontSize: 18,
  },
  logo: {
    marginRight: 30,
    maxHeight: 100,
    maxWidth: 350,
    height: 'min-content',
  },
  defaultLogo: {
    maxHeight: 110,
    maxWidth: 350,
    color: palette.grey[600],
    [breakpoints.down('sm')]: {
      maxHeight: 25,
      maxWidth: 100,
    },
  },
  shortcutContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  shortcut: {
    'verticalAlign': 'middle',
    'textDecoration': 'none',
    'margin': '0px 20px 10px 0px',
    '&:hover': {
      'textDecoration': 'none',
      '& $shortcutIcon': {
        borderColor: palette.yellow[600],
        boxShadow: `0 0 0 10px ${palette.grey[400]}`,
      },
      '& $shortcutLabel': {
        color: palette.grey[600],
      },
    },
  },
  disabled: {
    opacity: '0.4',
    pointerEvents: 'none',
  },
  shortcutIcon: {
    margin: 10,
    borderRadius: 15,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: '#b4b4b4',
    boxShadow: `0 0 0 10px ${palette.grey[100]}`,
    width: 125,
    height: 125,
    backgroundColor: 'white',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 90,
    [breakpoints.down('sm')]: {
      height: 65,
      width: 65,
      backgroundSize: 60,
      borderRadius: 5,
      marginLeft: 40,
    },
  },
  shortcutLabel: {
    color: palette.blue[1200],
    fontSize: defaultTheme.typography.shortcutText.fontSize,
    fontFamily: 'Arial, sans-serif',
    fontWeight: 'bold',
    width: 145,
    whiteSpace: 'normal',
    paddingTop: 10,
    textAlign: 'center',
    [breakpoints.down('sm')]: {
      width: 150,
    },
  },
});
