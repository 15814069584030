import { CssBaseline } from '@lsy-netline-ui/netline-ui';

import { useConnectionChange, useDeleteCookie } from '@crew-webui/common/hooks';
import { ConfirmationForRedirect, ProgressIndicator } from '@crew-webui/common/view/components';

import StartPage from './StartPage/StartPage';

const App = () => {
  useConnectionChange();
  useDeleteCookie();

  return (
    <>
      <CssBaseline />
      <ProgressIndicator />
      <ConfirmationForRedirect>
        <StartPage />
      </ConfirmationForRedirect>
    </>
  );
};

export default App;
